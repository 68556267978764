import React from 'react'
import { css } from 'styled-components'

import { space, breakpoints, fontSizes, colors, mediaQueries, letterSpacings, palette } from '../../utils/tokens'

const HowItWorksSection = (props) => {
  const { steps } = props
  return (
    <section
      css={css({
        padding: `${space[5]}px ${space[4]}px`,
        backgroundColor: colors.base,
        [mediaQueries.lg]: {
          padding: `${space[6]}px ${space[5]}px`,
        },
      })}
    >
      <div
        css={css({
          maxWidth: breakpoints.xxl,
          margin: `0 auto`,
        })}
      >
        <h3
          css={css({
            fontWeight: `bold`,
            fontSize: fontSizes[4],
            color: colors.white,
            textAlign: `center`,
            marginBottom: `${space[4]}px`,
            [mediaQueries.lg]: {
              fontSize: fontSizes[5],
              marginBottom: `${space[5]}px`,
            },
          })}
        >
          How it works
        </h3>
        {steps && (
          <ol
            css={css({
              listStyle: `none`,
              display: `grid`,
              gridGap: `${space[5]}px`,
              [mediaQueries.lg]: {
                gridTemplateColumns: `repeat(3, 1fr)`,
                gridGap: `${space[6]}px`,
              },
            })}
          >
            {steps.map((step, index) => {
              return (
                <li
                  key={step.id}
                  css={css({
                    display: `flex`,
                    flexDirection: `column`,
                    position: `relative`,
                  })}
                >
                  {step.icon &&
                    <img
                      src={step.icon.file.url}
                      alt={step.icon.title}
                      css={css({
                        maxHeight: `150px`,
                        marginBottom: `${space[4]}px`,
                      })}
                    />
                  }
                  <div
                    css={css({
                      display: `grid`,
                      gridTemplateColumns: `auto 1fr`,
                      alignItems: `center`,
                    })}
                  >
                    <div
                      css={css({
                        borderRadius: `100%`,
                        backgroundColor: colors.primaryBrand,
                        width: `32px`,
                        height: `32px`,
                        display: `flex`,
                        justifyContent: `center`,
                        alignItems: `center`,
                        marginRight: `${space[3]}px`,
                      })}
                    >
                      <span
                        css={css({
                          fontWeight: `bold`,
                          color: colors.white,
                        })}
                      >
                        {index + 1}
                      </span>
                    </div>
                    <h5
                      css={css({
                        color: colors.white,
                        fontSize: fontSizes[3],
                        fontWeight: `bold`,
                        textAlign: `left`,
                        [mediaQueries.xxl]: {
                          width: `100%`,
                        }
                      })}
                    >
                      {step.title}
                    </h5>
                  </div>

                  {step.instructions && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: step.instructions.childMarkdownRemark.html,
                      }}
                      css={css({
                        color: palette.grey[20],
                        marginTop: `${space[3]}px`,
                        letterSpacing: letterSpacings.normal,
                        fontSize: fontSizes[3],
                      })}
                    />
                  )}
                </li>
              )
            })}
          </ol>
        )}
      </div>
    </section>
  )
}

export default HowItWorksSection