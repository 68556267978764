import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { css } from 'styled-components'

import {
  mediaQueries,
  space,
  breakpoints,
  fontSizes,
  colors,
} from '../utils/tokens'
import HeroButtons from './HeroButtons'

const HeroTwoColumnsImageGrid = ({ hero, featuredCompanies, buttonGroup }) => {
  return (
    <section
      css={{
        [mediaQueries.lg]: {
          padding: `${space[5]}px 0`,
        },
      }}
    >
      <div
        css={css({
          maxWidth: breakpoints.xxl,
          margin: `auto`,
          padding: `${space[4]}px ${space[4]}px ${space[5]}px`,
          display: `flex`,
          flexDirection: `column-reverse`,
          [mediaQueries.xxl]: {
            display: `grid`,
            gridTemplateColumns: `auto 1fr`,
            gridGap: `${space[5]}px`,
            padding: 0,
          },
        })}
      >
        <div
          css={css({
            [mediaQueries.lg]: {
              display: `flex`,
              flexDirection: `column`,
              alignItems: `flex-start`,
              maxWidth: `${breakpoints.sm}`,
            },
            [mediaQueries.xxl]: {
              marginLeft: `${space[6]}px`,
              padding: `${space[5]}px 0`,
            },
          })}
        >
          <h1
            css={css({
              textTransform: `uppercase`,
              letterSpacing: `1.5px`,
              marginBottom: `${space[2]}px`,
            })}
          >
            {hero.subtitle && (
              <span
                css={css({
                  fontSize: fontSizes[2],
                  display: `block`,
                  color: colors.primaryBrand,
                  [mediaQueries.lg]: {
                    fontSize: fontSizes[2],
                  },
                })}
              >
                {hero.subtitle.internal.content}&nbsp;
              </span>
            )}
            <span
              css={css({
                fontSize: fontSizes[4],
                fontWeight: `bold`,
                [mediaQueries.lg]: {
                  fontSize: fontSizes[5],
                },
              })}
            >
              {hero.title}
            </span>
          </h1>
          {hero.tagline && (
            <div
              dangerouslySetInnerHTML={{
                __html: hero.tagline.childMarkdownRemark.html,
              }}
              css={css({
                fontSize: fontSizes[3],
                lineHeight: 1.2,
                marginTop: `${space[0]}px`,
                marginBottom: `${space[4]}px`,
              })}
            />
          )}
          <div
            css={css({
              display: `flex`,
              flexDirection: `column`,
              alignItems: `center`,
            })}
          >
            {buttonGroup ? (
              buttonGroup
            ) : (
              <HeroButtons
                callToActionUrl={hero.callToActionUrl}
                callToActionText={hero.callToActionText}
                callToActionUrlSecondary={hero.callToActionUrlSecondary}
                callToActionTextSecondary={hero.callToActionTextSecondary}
              />
            )}
          </div>
          {featuredCompanies && (
            <div
              css={css({
                marginTop: `${space[5]}px`,
              })}
            >
              <div
                css={css({
                  display: `grid`,
                  gridGap: `${space[4]}px`,
                  gridTemplateColumns: `repeat(3, 80px)`,
                  justifyContent: `center`,
                  alignItems: `center`,
                })}
              >
                {featuredCompanies.map((company, id) => {
                  return (
                    <div key={id}>
                      <img
                        src={company.file.url}
                        alt={company.title}
                        css={css({
                          filter: `grayscale(1)`,
                          opacity: 0.75,
                          width: `auto`,
                          margin: `auto`,
                        })}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
        {hero.backgroundPhoto && hero.backgroundCarousel ? (
          <div
            css={css({
              display: `none`,
              [mediaQueries.xxl]: {
                display: `grid`,
                gridTemplateColumns: `repeat(12, 1fr)`,
                gridTemplateRows: `minmax(150px, 300px)`,
                gridGap: `${space[3]}px`,
                '> :nth-child(1)': {
                  gridColumn: `span 6`,
                },
                '> :nth-child(2)': {
                  gridColumn: `span 6`,
                },
                '> :nth-child(3)': {
                  gridColumn: `span 5`,
                },
                '> :nth-child(4)': {
                  gridColumn:
                    hero.backgroundCarousel.length === 4 ? `span 7` : `span 5`,
                },
                '> :nth-child(5)': {
                  gridColumn: `span 4`,
                },
              },
            })}
          >
            {hero.backgroundCarousel.map(card => {
              return (
                <div key={card.id}>
                  <Img
                    fluid={card.media.fluid}
                    alt={card.media.title}
                    css={css({
                      width: `100%`,
                      height: `100%`,
                    })}
                  />
                </div>
              )
            })}
          </div>
        ) : (
          hero.backgroundPhoto && (
            <div
              css={css({
                marginBottom: `${space[4]}px`,
                [mediaQueries.lg]: {
                  marginBottom: 0,
                },
              })}
            >
              <Img
                fluid={hero.backgroundPhoto.fluid}
                alt={hero.backgroundPhoto.title}
              />
            </div>
          )
        )}
      </div>
    </section>
  )
}

export default HeroTwoColumnsImageGrid

HeroTwoColumnsImageGrid.propTypes = {
  hero: PropTypes.object,
  featuredCompanies: PropTypes.arrayOf(PropTypes.object),
  typeform: PropTypes.string,
}