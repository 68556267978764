import React from 'react'
import { graphql } from 'gatsby'
import { css } from 'styled-components'
import { Helmet } from 'react-helmet'

import SEO from '../components/SEO'
import Header from '../components/CampaignLandingPage/Header'
import HeroTwoColumns from '../components/HeroTwoColumns'
import HeroFullBackground from '../components/HeroFullBackground'
import HeroHalfBackgroundCarousel from '../components/HeroHalfBackgroundCarousel'
import HeroTwoColumnsImageGrid from '../components/HeroTwoColumnsImageGrid'
import CampaignLayout from '../components/CampaignLandingPage/CampaignLayout'
import HowItWorksSection from '../components/CampaignLandingPage/HowItWorksSection'
import {
  AlternatingRowsSection,
  TheProcessSection,
  FAQSection,
  MaterialsListSection,
  FeaturedWorkSection,
  ReviewsSection,
  GoogleMapEmbedSection,
  WhyUsSection
} from '../components/Section'

import { colors, fontSizes, mediaQueries, space, breakpoints } from '../utils/tokens'

const CampaignLandingPageTemplate = (props) => {
  const {
    metaTitle,
    metaDescription,
    hero,
    howItWorks,
    theProcess,
    featuredCompanies,
    whyUs,
    slug,
    materials,
    featuredWork,
    gallery,
    sections,
    isIndexed,
    frequentlyAskedQuestions,
    typeform,
    testimonials,
    showDrift,
  } = props.data.contentfulCampaignLandingPage

  const showHero = (hero) => {
    if(hero.type === `full background`) {
      return <HeroFullBackground hero={hero} featuredCompanies={featuredCompanies} typeform={typeform} />
    } else if(hero.type === `two columns with image grid`) {
      return (
        <HeroTwoColumnsImageGrid
          hero={hero}
          featuredCompanies={featuredCompanies}
          typeform={typeform}
        />
      )
    } else if (hero.type === `half background carousel`) {
      return (
        <HeroHalfBackgroundCarousel
          hero={hero}
        />
      )
    } else {
      return (
        <HeroTwoColumns
          hero={hero}
          featuredCompanies={featuredCompanies}
          typeform={typeform}
        />
      )
    }
  }

  const postNode = {
    title: metaTitle,
    metaDescription,
  }

  const galleryPath = gallery ? `/gallery/${gallery.slug}/` : null

  return (
    <CampaignLayout isIndexed={isIndexed}>
      <Helmet>
        <title>{postNode.title}</title>
      </Helmet>
      <SEO postNode={postNode} customTitle pageSEO />
      <Header dark={hero && hero.type !== `full background`} />
      <main
        css={css({
          paddingTop: `${space[5]}px`,
          [mediaQueries.xxl]: {
            paddingTop: 0,
          },
        })}
      >
        {hero && showHero(hero)}
        {whyUs && <WhyUsSection cards={whyUs} />}
        {featuredWork && (
          <FeaturedWorkSection
            featuredWork={featuredWork}
            seeMoreLink={galleryPath}
          />
        )}
        {howItWorks && <HowItWorksSection steps={howItWorks} />}
        {materials && <MaterialsListSection materials={materials} />}
        {theProcess && <TheProcessSection cards={theProcess} />}
        {slug === `laser-cutting-and-engraving-service`
          ? materials && (
              <section
                css={css({
                  padding: `${space[4]}px 0`,
                  backgroundColor: colors.lightGray,
                  [mediaQueries.lg]: {
                    padding: `${space[6]}px 0`,
                  },
                })}
              >
                <div
                  css={css({
                    padding: `0 ${space[4]}px`,
                    [mediaQueries.lg]: {
                      maxWidth: breakpoints.xl,
                      margin: `0 auto`,
                      padding: 0,
                    },
                  })}
                >
                  <h3
                    css={css({
                      fontWeight: `bold`,
                      fontSize: fontSizes[4],
                      marginBottom: `${space[4]}px`,
                      maxWidth: breakpoints.sm,
                      [mediaQueries.lg]: {
                        fontSize: fontSizes[5],
                        marginBottom: `${space[5]}px`,
                      },
                    })}
                  >
                    Materials we can laser cut and engrave
                  </h3>
                  <div
                    css={css({
                      display: `grid`,
                      gridGap: `${space[4]}px`,
                      [mediaQueries.lg]: {
                        gridTemplateColumns: `repeat(3, 1fr)`,
                        maxWidth: breakpoints.md,
                      },
                    })}
                  >
                    {materials.map((material, id) => {
                      return (
                        <div key={id}>
                          <h5
                            css={css({
                              fontWeight: `bold`,
                              fontSize: fontSizes[3],
                              marginBottom: `${space[3]}px`,
                            })}
                          >
                            {material.name}
                          </h5>
                          <ul
                            css={css({
                              listStyle: `none`,
                            })}
                          >
                            {material.types.map((type, id) => {
                              return (
                                <li
                                  key={id}
                                  css={css({
                                    marginBottom: `${space[2]}px`,
                                    fontSize: fontSizes[3],
                                  })}
                                >
                                  {type}
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      )
                    })}
                  </div>
                  <p
                    css={css({
                      marginTop: `${space[4]}px`,
                      fontSize: fontSizes[4],
                      fontWeight: `bold`,
                      color: colors.base,
                      [mediaQueries.lg]: {
                        marginTop: `${space[5]}px`,
                      },
                    })}
                  >
                    ....and many more!
                  </p>
                </div>
              </section>
            )
          : null}
        {testimonials && <ReviewsSection reviews={testimonials} />}
        {frequentlyAskedQuestions && (
          <FAQSection faqs={frequentlyAskedQuestions} />
        )}
        {sections &&
          sections.map(section => {
            if (section.type === `alternating rows`) {
              return (
                <AlternatingRowsSection key={section.id} section={section} />
              )
            } else if (section.type === `google map embed`) {
              return (
                <GoogleMapEmbedSection key={section.id} section={section} />
              )
            } else {
              return null
            }
          })}
      </main>
    </CampaignLayout>
  )
}

export default CampaignLandingPageTemplate

export const CampaignLandingPageTemplateQuery = graphql`
    query($slug: String!) {
      contentfulCampaignLandingPage(slug: { eq: $slug }) {
        name
        slug
        metaTitle
        showDrift
        metaDescription {
          internal {
            content
          }
        }
        isIndexed
        typeform
        hero {
          title
          type
          tagline {
            childMarkdownRemark {
              html
            }
          }
          subtitle {
            childMarkdownRemark {
              html
            }
            internal {
              content
            }
          }
          callToActionText
          callToActionUrl
          callToActionTextSecondary
          callToActionUrlSecondary
          backgroundPhoto {
            title
            mobileFluid: fluid(maxWidth: 400) {
            ...GatsbyContentfulFluid_withWebp
          } 
            fluid(quality: 100, maxWidth: 500) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          backgroundCarousel {
            title
            media {
              title
              fluid(maxWidth: 300 quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
              hdFluid: fluid(maxWidth: 3000 quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
        featuredCompanies {
          title
          file {
            url
          }
        }
        featuredWork {
          title
          fluid(maxWidth: 300 quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        whyUs {
          title
          subtitle
          media {
            title
            file {
              url
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
        materials {
          name
          types
          description {
            childMarkdownRemark {
              html
            }
          }
          coverPhoto {
            title
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        gallery {
          slug
          images {
            ...RecentWorkImages
          }
        }
        testimonials {
          reviewer
          review {
            childMarkdownRemark {
              html
            }
            internal {
              content
            }
          }
        }
        howItWorks {
          ...HowItWorksCardFragment
        }
        theProcess {
          title
          media {
            file {
              url
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
        frequentlyAskedQuestions {
          question
          id
          answer {
            childMarkdownRemark {
              html
            }
          }
        }
        sections {
          ... on ContentfulPageSection {
            id
            title
            subtitle
            body {
              childMarkdownRemark {
                html
              }
            }
            type
            cards {
              ... on ContentfulCard {
                title
                subtitle
                media {
                  title
                  fluid(maxWidth: 4000, maxHeight: 2000) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `